import { useEffect, useState } from "react";
import store, { useAppSelector } from "../../Redux/Store";
import linesService from "../../Services/Lines";
import AdminLineCard from "./AdminLineCard";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import LinesInfo from "./LinesInfo";


function LinesList(): JSX.Element {
    const [linesData, setLinesData] = useState(store.getState().linesState.lines);
    // const params = useParams();
    // console.log(params._id)

    useEffect(() => {
        if (!linesData || linesData.length === 0) {
            linesService.getAllLinesAsync()
                .then(res => setLinesData(res));
        }
        const interval = setInterval(() => {
            linesService.getAllLinesAsync(true);
        }, 180000);
        return () => clearInterval(interval);
    }, [])

    return <div className="LinesList gradient-bg">
        <ToggleButtonGroup
            color="primary"
            value="lines"
            exclusive
            aria-label="Platform"
        >
            <ToggleButton value="lines">רשימת תורים</ToggleButton>
            <Link to="/manage/settings"> <ToggleButton value="settings">הגדרות</ToggleButton></Link>
        </ToggleButtonGroup>
        <h2 id="lines-list-h2">התורים שלך</h2>
        {linesData && <>
            <LinesInfo setLines={setLinesData} />
            <div className="list-container">
                {linesData.length === 0 && <h3> אין תוצאות</h3>}
                {[...linesData].sort((a, b) => (+a.timestamp) - (+b.timestamp)).map(l => <AdminLineCard key={l._id} line={l} />)}
            </div>
        </>
        }
    </div>
}

export default LinesList;