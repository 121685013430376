import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useAppSelector } from '../../Redux/Store';
import LineModel from '../../Models/LineModel';



function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
interface LinesInfoProps {
    setLines: React.Dispatch<React.SetStateAction<LineModel[]>>;
}

const mapLines = (adminIncome:number,linesData: LineModel[], sortValue: number, setLinesData?: React.Dispatch<React.SetStateAction<LineModel[]>>): { income: number, linesSum: number } => {
    let income = 0;
    let linesSum = 0;
    let mappedLines: LineModel[] = [];
    switch (sortValue) {
        case 1:
            linesData.forEach(l => {
                const date = new Date(+l.timestamp);
                const isToday = date.toDateString() === new Date().toDateString();
                if (isToday) {
                    income += adminIncome;
                    linesSum += 1;
                    mappedLines.push(l);
                }
            });
            break;
        case 2:
            linesData.forEach(l => {
                const date = new Date(+l.timestamp);
                const now = new Date();
                const oneWeekFromNow = new Date(now.getTime() + 7 * 24 * 60 * 60 * 1000);

                date.setHours(0, 0, 0, 0);
                oneWeekFromNow.setHours(0, 0, 0, 0);

                if (date.getTime() < oneWeekFromNow.getTime()) {
                    income += adminIncome;
                    linesSum += 1;
                    mappedLines.push(l);
                }
            });
            break;
        case 3:
            linesData.forEach(l => {
                const date = new Date(+l.timestamp);
                const oneMonthFromNow = new Date();
                oneMonthFromNow.setMonth(oneMonthFromNow.getMonth() + 1);

                date.setHours(0, 0, 0, 0);
                oneMonthFromNow.setHours(0, 0, 0, 0);

                if (date.getTime() < oneMonthFromNow.getTime()) {
                    income += adminIncome;
                    linesSum += 1;
                    mappedLines.push(l);
                }
            });
            break;
        default:
            //0
            linesData.forEach(l => {
                income += adminIncome;
                linesSum += 1;
                mappedLines.push(l);
            });

    }
    if (setLinesData) setLinesData(mappedLines);

    return {
        income,
        linesSum
    }
};

export default function LinesInfo(props: LinesInfoProps) {
    const [value, setValue] = React.useState(0);
    const allLines = useAppSelector(state => state.linesState.lines);
    const adminData = useAppSelector(state => state.authState.admin);
    const [linesSums, setLinesSums] = React.useState({ income: 0, linesSum: 0 });

    React.useEffect(() => {
        setLinesSums(mapLines(adminData.incomePerLine,allLines, value, props.setLines));
    }, [allLines])

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        setLinesSums(mapLines(adminData.incomePerLine,allLines, newValue, props.setLines));
    }

    return (
        <Box className="LinesInfo" sx={{ width: '100%', maxWidth: '600px', bgcolor: '#dedddd6a', borderRadius: '7px', m: 2 }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', borderRadius: '7px 7px 0 0 ', bgcolor: 'InfoBackground' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered>
                    <Tab label="הכל" {...a11yProps(0)} />
                    <Tab label="היום" {...a11yProps(1)} />
                    <Tab label="השבוע הקרוב" {...a11yProps(2)} />
                    <Tab label="החודש הקרוב" {...a11yProps(3)} />
                </Tabs>
            </Box>
            <div className="sumDiv">
                <div className="linesSum">
                    <h1>{linesSums.linesSum}</h1>
                    <h3>מספר תורים</h3>
                </div>
                <div className="income">
                    <h1>{linesSums.income} &#8362;</h1>
                    <h3>הכנסה צפויה</h3>
                </div>
            </div>

        </Box>
    );
}
